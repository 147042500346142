'use client';

import { Button, Grid, Text } from 'geist/components';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import type { BlogPostCategoryPageProps } from '@/(blog)/types';
import { fetchMoreBlogPostsInCategory } from '@/(blog)/lib/actions';
import { CATEGORY_PAGE_SIZE } from '@/(blog)/lib/api';
import { usePaginateFetch } from '@/(blog)/lib/use-paginate-fetch';
import { BlogGridViewHero } from '../hero';
import { ArticleCard } from '../article-card';
import styles from './page.module.css';

export function BlogPostCategoryPage({
  category,
  heroPosts,
  initialPosts,
  isDraft,
}: BlogPostCategoryPageProps): JSX.Element {
  const {
    data: posts,
    loading,
    loadMore,
    hasMore,
  } = usePaginateFetch({
    initialData: initialPosts,
    pageSize: CATEGORY_PAGE_SIZE,
    serverAction: (page) =>
      fetchMoreBlogPostsInCategory(category, page, isDraft),
    id: category,
  });

  const rows = {
    sm: posts.length + 2,
    md: Math.ceil(posts.length / 2) + 2,
    lg: Math.ceil(posts.length / 3) + 2,
  }; // add a row for the 'show more' button
  const lastRow = {
    sm: `${rows.sm}/${rows.sm + 1}`,
    md: `${rows.md}/${rows.md + 1}`,
    lg: `${rows.lg}/${rows.lg + 1}`,
  } as const;

  return (
    <>
      <BlogGridViewHero posts={heroPosts} />

      <Grid
        aria-live="polite"
        as="ul"
        columns={{
          sm: 2,
          lg: 3,
        }}
        dashedGuides
        hideGuides="row"
        role="region"
        rows={rows}
      >
        <Grid.Cell className={styles.headingContainer} column="1/-1" row={1}>
          <Text
            as="h1"
            variant={{
              sm: 'heading-24',
              md: 'heading-32',
            }}
          >
            Latest news.
          </Text>
        </Grid.Cell>
        {posts.map((post) => (
          <Grid.Cell
            as="li"
            className={styles.smallCards}
            column={{
              sm: '1/-1',
              md: 'auto',
            }}
            key={post.slug}
            noPadding
          >
            <ArticleCard {...post} />
          </Grid.Cell>
        ))}
        <Grid.Cell
          column="1/-1"
          row={lastRow}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {hasMore ? (
            <Button
              className={styles.showMoreButton}
              disabled={loading}
              loading={loading}
              onClick={() => {
                void loadMore();

                analytics.track(AnalyticsEvent.CLICK_EVENT, {
                  click_name: 'blog_interaction',
                  click_value: 'show more',
                });
              }}
              shadow
              shape="rounded"
              type="secondary"
              typeName="button"
            >
              Show more posts
            </Button>
          ) : null}
        </Grid.Cell>
      </Grid>
    </>
  );
}
