'use client';

import { useState, useCallback, useEffect } from 'react';
import create from 'zustand';

interface CacheEntry<T> {
  page: number;
  data: T[];
}

interface StoreState {
  cache: Record<string, CacheEntry<unknown>>;
  updateCache: <T>(id: string, page: number, data: T[]) => void;
  getCacheEntry: <T>(id: string) => CacheEntry<T> | undefined;
}

const useStore = create<StoreState>((set, get) => ({
  cache: {},
  updateCache: <T>(id: string, page: number, data: T[]) => {
    set((state) => ({
      cache: {
        ...state.cache,
        [id]: { page, data },
      },
    }));
  },
  getCacheEntry: <T>(id: string): CacheEntry<T> | undefined => {
    return get().cache[id] as CacheEntry<T> | undefined;
  },
}));

export enum BlogPages {
  CHANGELOG = 'changelog',
  PRESS = 'press',
}

export function usePaginateFetch<T>({
  initialData,
  pageSize,
  serverAction,
  id,
}: {
  initialData: T[];
  pageSize: number;
  serverAction: (page: number) => Promise<T[]>;
  id: string;
}): {
  data: T[];
  loading: boolean;
  hasMore: boolean;
  loadMore: () => Promise<void>;
  highestLoadedPage: number;
} {
  const { updateCache, getCacheEntry } = useStore();

  const cachedEntry = getCacheEntry<T>(id);
  const [data, setData] = useState<T[]>(cachedEntry?.data ?? initialData);

  const [prefetchedData, setPrefetchedData] = useState<T[]>([]);
  const [hasPrefetched, setHasPrefetched] = useState<boolean>(false);
  const [page, setPage] = useState<number>(cachedEntry?.page ?? 1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchData = useCallback(
    async (pageToFetch: number) => {
      try {
        const newData = await serverAction(pageToFetch);
        return newData;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Failed to fetch data for page ${pageToFetch}`, e);
        return [];
      }
    },
    [serverAction],
  );

  const prefetchNextPage = useCallback(async () => {
    if (hasPrefetched || cachedEntry) return;
    try {
      const newData = await fetchData(page);
      setPrefetchedData(newData);
      setHasPrefetched(true);
      if (newData.length === 0 || newData.length < pageSize) {
        setHasMore(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [page, pageSize, fetchData, hasPrefetched, cachedEntry]);

  useEffect(() => {
    void prefetchNextPage();
  }, [prefetchNextPage]);

  useEffect(() => {
    updateCache(id, page, data);
  }, [id, page, data, updateCache]);

  async function loadMore(): Promise<void> {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      let newData: T[];
      if (prefetchedData.length > 0) {
        newData = prefetchedData;
        setPrefetchedData([]);
      } else {
        newData = await fetchData(page);
      }
      if (newData.length === 0 || newData.length < pageSize) {
        setHasMore(false);
      }

      const uniqueNewData = newData.filter(
        (item) =>
          !data.some(
            (existingItem) =>
              JSON.stringify(existingItem) === JSON.stringify(item),
          ),
      );

      // Prevent duplicate entries
      if (uniqueNewData.length > 0) {
        const updatedData = [...data, ...uniqueNewData];
        setData(updatedData);
        setPage(page + 1);
        await prefetchNextPage();
      } else if (newData.length > 0) {
        // If all new items were duplicates, try to fetch the next page
        setPage(page + 1);
        await loadMore();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return { data, loading, hasMore, loadMore, highestLoadedPage: page };
}
