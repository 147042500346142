'use client';

import { Grid } from 'geist/components';
import type { BlogPost } from '@/(blog)/types';
import { ArticleCard } from '@/(blog)/(root)/blog/category/components/article-card';
import styles from './hero.module.css';

export function BlogGridViewHero({
  posts,
}: {
  posts: BlogPost[];
}): JSX.Element {
  return (
    <Grid
      as="ul"
      columns={{
        sm: 1,
        lg: 3,
      }}
      rows={{
        sm: 3,
        lg: 1,
      }}
    >
      <h1 className="geist-sr-only">Featured articles</h1>
      {posts.map((post, index) => (
        <Grid.Cell as="li" className={styles.cell} key={post.slug} noPadding>
          <ArticleCard
            {...post}
            featured
            index={index}
            key={post.slug}
            large={index === 0}
          />
        </Grid.Cell>
      ))}

      <Grid.Cross column={1} row={1} />
    </Grid>
  );
}
