import type { HeadingTextVariant } from 'geist/components';
import { Text, Stack, Badge } from 'geist/components';
import Link from 'next/link';
import { clsx } from 'clsx';
import { documentToPlainTextString } from '@pyra/contentful/utils/document-to-plain-text-string';
import type { ResponsiveProp } from 'geist/src/types/other';
import { useRef } from 'react';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import { AnimatedPixelIcon } from '@pyra/marketing-shared/animated-pixel-icon';
import { formatArticleDate } from '@/(blog)/lib/utils/format-article-date';
import { BlogAuthors } from '@/(blog)/components/author';
import type { BlogPost } from '../../../../../types';
import styles from './article-card.module.css';

interface BasicArticleCard extends BlogPost {
  featured?: never;
  large?: never;
  index?: never;
  isDraft: boolean;
}

interface FeaturedArticleCard extends BlogPost {
  large?: boolean;
  featured?: boolean;
  index?: number;
  isDraft?: boolean;
}

type ArticleCardProps = BasicArticleCard | FeaturedArticleCard;

export function ArticleCard({
  title,
  date,
  pixelIcon,
  slug,
  intro,
  authors,
  large,
  content,
  featured,
  category,
  index,
  isDraft,
}: ArticleCardProps): JSX.Element {
  const isActive = useRef(false);
  const formattedDate = formatArticleDate(date);

  const getTextContent = (): string | JSX.Element | null => {
    const contentString = documentToPlainTextString(content);

    if (!intro) {
      return contentString;
    }

    const introString = documentToPlainTextString(intro);

    if (introString.length < 400) {
      return (
        <>
          {introString} {contentString.slice(0, 500)}...
        </>
      );
    }

    return introString;
  };

  const textContent = getTextContent();

  const getHeadingVariant = (): ResponsiveProp<HeadingTextVariant> => {
    if (featured) {
      return {
        sm: large ? 'heading-24' : 'heading-20',
        md: large ? 'heading-32' : 'heading-24',
      } as const;
    }
    return {
      sm: 'heading-16',
      md: 'heading-20',
    } as const;
  };

  const featuredCardEvents = featured
    ? {
        onMouseEnter: (): void => {
          isActive.current = true;
        },
        onMouseLeave: (): void => {
          isActive.current = false;
        },
        onFocus: (): void => {
          isActive.current = true;
        },
        onBlur: (): void => {
          isActive.current = false;
        },
      }
    : {};

  const getCategoryLabel = (): string => {
    if (!category?.name) {
      return 'General';
    }

    if (category.name === 'Customer stories') {
      return 'Customers';
    }

    return category.name;
  };

  return (
    <article
      aria-label={title}
      className={clsx(styles.card, {
        [styles.featured]: featured,
        [styles.highlightedCard]: large,
      })}
    >
      <Link
        className={styles.post}
        href={`/blog/${slug}`}
        {...featuredCardEvents}
        onClick={() => {
          // we only want to track clicks on featured posts
          if (!featured) return;

          const featureName = {
            0: 'Feature Left',
            1: 'Feature Middle',
            2: 'Feature Right',
          };

          analytics.track(AnalyticsEvent.CLICK_EVENT, {
            click_name: 'blog_feature_click',
            click_value:
              featureName[index as keyof typeof featureName] || 'Feature',
          });
        }}
      >
        <div className={styles.label}>
          <Stack align="center" direction="row" gap={2}>
            {isDraft ? (
              <Badge size="sm" variant="purple">
                Draft
              </Badge>
            ) : null}
            {featured ? (
              <AnimatedPixelIcon
                iconName={pixelIcon || 'vercel'}
                isActive={isActive}
              />
            ) : (
              <Text as="span" color="gray-900" variant="label-13">
                {getCategoryLabel()}
              </Text>
            )}
          </Stack>

          <Text as="time" color="gray-900" dateTime={date} variant="label-13">
            {formattedDate}
          </Text>
        </div>

        <Text as="h2" variant={getHeadingVariant()}>
          {title}
        </Text>

        {textContent ? (
          <div className={styles.introContainer}>
            <Text
              className={styles.intro}
              color="gray-900"
              variant={{
                sm: 'copy-14',
              }}
            >
              {textContent}
            </Text>
          </div>
        ) : null}
        <BlogAuthors authors={authors} className={styles.authors} />
      </Link>
    </article>
  );
}
