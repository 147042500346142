
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"0a74ed83954798b17f2fa4045e50dc9c5027673b":"fetchMoreBlogChangelogItems","92a146d849ea789d99bf9d354af3d0a244ba47b6":"fetchMoreBlogNewsroomArticles","be89baf145f4a7011bf39d88be4749d07b79b821":"fetchMoreBlogPostsInCategory"} */ export var fetchMoreBlogChangelogItems = createServerReference("0a74ed83954798b17f2fa4045e50dc9c5027673b");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var fetchMoreBlogPostsInCategory = createServerReference("be89baf145f4a7011bf39d88be4749d07b79b821");
export var fetchMoreBlogNewsroomArticles = createServerReference("92a146d849ea789d99bf9d354af3d0a244ba47b6");

