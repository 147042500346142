import { notFound } from '@pyra/vercel-segment/navigation';
import {
  makeDraftable,
  parseEntryId,
  ParseError,
} from '@pyra/contentful/parse';
import { APP_DEFAULT_LOCALE_CODE } from '@pyra/marketing-shared/locales';
import { getContentfulClient } from '@pyra/contentful/rpc';
import {
  categorySlugToKey,
  parseBlogChangelogItem,
  parseBlogNewsroomArticle,
  parseBlogPost,
  parseBlogPostSlug,
  parseBlogRecommendedPostsHero,
} from '@/(blog)/lib/parse-api';
import type {
  BlogChangelogItem,
  BlogNewsroomArticle,
  BlogPostCategoryPageProps,
} from '@/(blog)/types';
import { blogCategoryKeys, type BlogPost } from '@/(blog)/types';

export async function getBlogPostCategoryPageProps(
  category: string,
  isDraft = false,
): Promise<BlogPostCategoryPageProps> {
  const categoryKey = categorySlugToKey(category);
  // @ts-expect-error - we want to know if we're missing a category
  if (!blogCategoryKeys.includes(categoryKey)) return notFound();

  const [initialPosts, heroPosts] = await Promise.all([
    getBlogPostsInCategory(category, isDraft),
    getBlogRecommendedPostsHeroForCategory(categoryKey, isDraft),
  ]);

  return {
    category,
    initialPosts,
    heroPosts,
    isDraft,
  };
}

export async function getAllBlogPostSlugs(): Promise<string[]> {
  const query: Record<string, unknown> = {
    content_type: 'blogPost',
    locale: APP_DEFAULT_LOCALE_CODE,
    limit: 1000,
  };
  const { items } = await getContentfulClient(false, true).getEntries(query);
  const parsed = items.map((item) => parseBlogPostSlug(item));
  return parsed.map((item) => item.slug);
}

export async function getBlogPost(
  slug: string,
  isDraft = false,
): Promise<BlogPost> {
  const query: Record<string, unknown> = {
    limit: 1,
    include: 3,
    'fields.slug': slug,
    content_type: 'blogPost',
    locale: APP_DEFAULT_LOCALE_CODE,
  };

  const { items } = await getContentfulClient(isDraft, isDraft).getEntries(
    query,
  );

  if (!items.length) return notFound();
  const [entry] = items;

  // If this is ever hanging forever its likely due to circular dependencies
  const draftable = makeDraftable(entry, isDraft);

  const parsed = parseBlogPost(draftable);
  const sysId = parseEntryId(entry);

  return { ...parsed, sysId };
}

export const CATEGORY_PAGE_SIZE = 12;

export async function getBlogPostsInCategory(
  category: string,
  isDraft = false,
  skip = 0,
): Promise<BlogPost[]> {
  const categorySysId = await getBlogCategorySysId(category);

  const query: Record<string, unknown> = {
    limit: CATEGORY_PAGE_SIZE,
    include: 3,
    order: '-fields.date',
    content_type: 'blogPost',
    locale: APP_DEFAULT_LOCALE_CODE,
    'fields.recommended': false,
    skip,
    ...(category !== 'all' ? { links_to_entry: categorySysId } : {}),
  };

  const { items } = await getContentfulClient(isDraft, isDraft).getEntries(
    query,
  );

  const parsedPosts = items
    .map((entry) => makeDraftable(entry, isDraft))
    .map(parseBlogPost);

  return parsedPosts;
}

export const getBlogRecommendedPostsHeroForCategory = async (
  category: string,
  isDraft = false,
): Promise<BlogPost[]> => {
  const {
    items: [entry],
  } = await getContentfulClient(isDraft, isDraft).getEntries({
    content_type: 'blogRecommendedPostsHero',
    include: 5,
    limit: 1,
  });

  const draftable = makeDraftable(entry, isDraft);
  const parsed = parseBlogRecommendedPostsHero(draftable);

  if (category in parsed) {
    return parsed[category as keyof typeof parsed].slice(0, 3);
  }

  throw new ParseError({
    type: 'leaf',
    message: `No category hero posts found for category '${category}'`,
  });
};

export const getBlogCategorySysId = async (
  category: string,
): Promise<string> => {
  if (category === 'all') return '';

  const query: Record<string, unknown> = {
    include: 1,
    'fields.slug': category,
    content_type: 'blogCategory',
  };
  const {
    items: [entry],
  } = await getContentfulClient().getEntries(query);
  return entry.sys.id;
};

export const PRESS_PAGE_SIZE = 8;

export async function getBlogNewsroomArticles(
  isDraft = false,
  skip = 0,
): Promise<BlogNewsroomArticle[]> {
  const query: Record<string, unknown> = {
    limit: PRESS_PAGE_SIZE,
    include: 3,
    content_type: 'newsroomArticle',
    order: '-fields.date',
    locale: APP_DEFAULT_LOCALE_CODE,
    skip,
  };

  const { items } = await getContentfulClient(isDraft, isDraft).getEntries(
    query,
  );

  const parsedPosts = items
    .map((entry) => makeDraftable(entry, isDraft))
    .map(parseBlogNewsroomArticle);

  return parsedPosts;
}

export const CHANGELOG_PAGE_SIZE = 3;

export async function getAllChangelogItemSlugs(): Promise<string[]> {
  const query: Record<string, unknown> = {
    content_type: 'changelogItem',
    locale: APP_DEFAULT_LOCALE_CODE,
    limit: 1000,
  };
  const { items } = await getContentfulClient(false, true).getEntries(query);
  const parsed = items.map((item) => parseBlogPostSlug(item));
  return parsed.map((item) => item.slug);
}

export async function getBlogChangelogItems(
  isDraft = false,
  skip = 0,
): Promise<BlogChangelogItem[]> {
  const query: Record<string, unknown> = {
    limit: CHANGELOG_PAGE_SIZE,
    include: 3,
    content_type: 'changelogItem',
    order: '-fields.date',
    locale: APP_DEFAULT_LOCALE_CODE,
    skip,
  };

  const { items } = await getContentfulClient(isDraft, isDraft).getEntries(
    query,
  );

  const parsedItems = items
    .map((entry) => makeDraftable(entry, isDraft))
    .map(parseBlogChangelogItem);

  return parsedItems;
}

export async function getChangelogItemBySlug(
  slug: string,
  isDraft = false,
): Promise<BlogChangelogItem> {
  const query: Record<string, unknown> = {
    limit: 1,
    include: 3,
    'fields.slug': slug,
    content_type: 'changelogItem',
    locale: APP_DEFAULT_LOCALE_CODE,
  };

  const { items } = await getContentfulClient(isDraft, isDraft).getEntries(
    query,
  );

  if (!items.length) notFound();
  const [entry] = items;

  const parsed = parseBlogChangelogItem(makeDraftable(entry, isDraft));
  const sysId = parseEntryId(entry);

  return { ...parsed, sysId };
}

export async function getBlogPostBySysId(
  id: string,
  isDraft = false,
): Promise<BlogPost> {
  const query: Record<string, unknown> = {
    limit: 1,
    include: 3,
    'sys.id': id,
    content_type: 'blogPost',
    locale: APP_DEFAULT_LOCALE_CODE,
  };

  const { items } = await getContentfulClient(isDraft, isDraft).getEntries(
    query,
  );

  if (!items.length) return notFound();
  const [entry] = items;

  const parsed = parseBlogPost(makeDraftable(entry, isDraft));
  const sysId = parseEntryId(entry);

  return { ...parsed, sysId };
}

export async function getChangelogBySysId(
  id: string,
  isDraft = false,
): Promise<BlogChangelogItem> {
  const query: Record<string, unknown> = {
    limit: 1,
    include: 3,
    'sys.id': id,
    content_type: 'changelogItem',
    locale: APP_DEFAULT_LOCALE_CODE,
  };

  const { items } = await getContentfulClient(isDraft, isDraft).getEntries(
    query,
  );

  if (!items.length) return notFound();
  const [entry] = items;

  const parsed = parseBlogChangelogItem(makeDraftable(entry, isDraft));
  const sysId = parseEntryId(entry);

  return { ...parsed, sysId };
}

export function extractSysIdFromSlug(slug: string):
  | {
      slugSysId: string;
      slugWithoutSysId: string;
    }
  | undefined {
  // Contentful entries have a system ID under sys.id
  // that looks like 5L5CshRtnh99LLjnNbPd3G. We use
  // these IDs to detect and differentiate
  // from the actual changelog slugs. This enables us to change
  // the slug of a changelog post without breaking the URL.
  const idPattern = /^[a-zA-Z0-9]{20,}$/;
  const slugParts = slug.split('-');
  const potentialId = slugParts[slugParts.length - 1];

  if (idPattern.test(potentialId)) {
    return {
      slugSysId: potentialId,
      slugWithoutSysId: slugParts.slice(0, -1).join('-'),
    };
  }

  return undefined;
}
