import type { MutableRefObject } from 'react';
import type { PixelIconName } from 'geist/symbols/pixel/name-to-icon';
import { pixelIconMatrices } from 'geist/symbols/pixel/pixel-icon-matrices';
import { clsx } from 'clsx';
import { PixelatedIcon } from '../pixelated-icon';
import styles from './animated-pixel-icon.module.css';

export function AnimatedPixelIcon({
  isActive,
  iconName,
  smallOnMobile,
}: {
  isActive: MutableRefObject<boolean>;
  iconName: PixelIconName;
  smallOnMobile?: boolean;
}): JSX.Element {
  return (
    <PixelatedIcon
      active={isActive}
      className={clsx(styles.icon, smallOnMobile && styles['small-on-mobile'])}
      higherContrast
      logo={pixelIconMatrices[iconName]}
      renderScale={4}
      useCanvas
    />
  );
}
